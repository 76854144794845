import React, { forwardRef, type ReactNode } from 'react'
import cx from 'classnames'


type WidthContainerCompensatorProps = {
  className?: string
  children?: ReactNode
  'data-testid'?: string
}

const WidthContainerCompensator = forwardRef<HTMLDivElement, WidthContainerCompensatorProps>((props, ref) => {
  const { className, children, 'data-testid': dataTestId } = props

  return (
    <div ref={ref} className={cx(className, '-mx-16')} data-testid={dataTestId}>
      {children}
    </div>
  )
})

WidthContainerCompensator.displayName = 'WidthContainerCompensator'


export default WidthContainerCompensator
