import React from 'react'
import cx from 'classnames'


const fitToClassName = {
  'cover': 'object-cover',
  'contain': 'object-contain',
}


type AspectContainerProps = {
  children: React.ReactElement<{ className?: string }>
  className?: string
  aspect?: number // w / h, e.g. 1.33
  fit?: 'cover' | 'contain'
  'data-testid'?: string
}

// It helps to create placeholders for images
const AspectContainer: React.FunctionComponent<AspectContainerProps> = (props) => {
  const { children, className, aspect = 1, fit = 'cover', 'data-testid': dataTestId } = props

  const placeholderStyle = {
    paddingTop: `${Math.round(100 / aspect)}%`,
  }

  const childClassName = cx('absolute left-0 top-0 size-full', children.props.className, fitToClassName[fit])

  return (
    <div className={cx(className, !className?.includes('absolute') && 'relative')} data-testid={dataTestId}>
      <div style={placeholderStyle} />
      {
        React.cloneElement(
          children,
          {
            className: childClassName,
          }
        )
      }
    </div>
  )
}


export default AspectContainer
