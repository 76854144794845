import React from 'react'
import cx from 'classnames'
import { WidthContainer } from 'components/layout'

import s from './SideBySideLayout.module.css'


type SideBySideLayoutProps = {
  className?: string
}

const SideBySideLayout: React.CFC<SideBySideLayoutProps> = (props) => {
  const { className, children } = props

  return (
    <WidthContainer className={cx(className, s.container)}>
      {children}
    </WidthContainer>
  )
}


export default SideBySideLayout
