import React from 'react'
import cx from 'classnames'


export const colorToClassName = {
  'gray-30': 'text-gray-30',
  'gold-50': 'text-gold-50',
} as const

export type DividerColor = keyof typeof colorToClassName

type DividerProps = {
  className?: string
  color?: DividerColor
}

const Divider: React.FunctionComponent<DividerProps> = (props) => {
  const { className, color = 'gray-30' } = props

  const rootClassName = cx('h-1 bg-[currentColor]', colorToClassName[color], className)

  return (
    <div className={rootClassName} />
  )
}


export default Divider
