import React from 'react'
import { Helmet, type HelmetProps } from 'react-helmet-async'


const htmlAttributes: HelmetProps['htmlAttributes'] = {
  // @ts-ignore there is an error in typings of Helmet
  style: 'background-color:#fff',
}

const WhitePageLayout: React.CFC = ({ children }) => (
  <>
    <Helmet htmlAttributes={htmlAttributes} />
    {children}
  </>
)


export default WhitePageLayout
