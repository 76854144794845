import React from 'react'
import cx from 'classnames'

import Cell from './Cell/Cell'
import type { GridCellProps } from './Cell/Cell'


export const aligns = [ 'center', 'start', 'end', 'baseline', 'stretch' ] as const
export const justifies = [ 'between', 'center', 'start', 'end' ] as const
export const columnsSizes = [ 1, 2, 3, 4, 5, 6, '2-center', '3-center', '4-center', '6-center' ] as const
export const gutterSizes = [ 8, 12, 16, 24, 32 ] as const

export type Align = typeof aligns[number]
export type Justify = typeof justifies[number]
export type ColumnsSize = typeof columnsSizes[number]
export type GutterSize = typeof gutterSizes[number]

export type GridProps = {
  className?: string
  columns: ColumnsSize
  gutter?: GutterSize
  align?: Align
  justify?: Justify
  role?: string
  noWrap?: boolean
  'data-testid'?: string
  dataAttributes?: Record<string, any>
}

type GridComponent = React.CFC<GridProps> & {
  Cell: React.CFC<GridCellProps>
}

const Grid: GridComponent = (props) => {
  const {
    children, className, columns = 1, gutter,
    align = 'start', justify = 'start', role = 'list', noWrap,
    'data-testid': dataTestId, dataAttributes,
  } = props

  const rootClassName = cx(className, 'grid', {
    'grid-flow-col': noWrap,
    [`items-${align}`]: align,
    [`grid-cols-${columns}`]: columns,
    [`justify-${justify}`]: justify,
    [`gap-${gutter}`]: gutter,
  })

  return (
    <div
      className={rootClassName}
      role={role}
      data-testid={dataTestId}
      {...dataAttributes}
    >
      {children}
    </div>
  )
}

Grid.Cell = Cell


export default Grid
