import React, { useMemo } from 'react'
import cx from 'classnames'

import s from './Bone.module.css'


type BoneProps = {
  className?: string
  bgColor?: 'light-beige' | 'gray-20' | 'gray-70' | 'gray-90'
  w?: number
  h?: number
  pw?: number // percent width
  ph?: number // percent height
  aspect?: number // w / h, e.g. 1.33
  circle?: boolean
  inline?: boolean
}

const Bone: React.FunctionComponent<BoneProps> = (props) => {
  const {
    className,
    w: width, h: height,
    pw: percentWidth, ph: percentHeight,
    aspect, circle, inline = false,
    bgColor = 'gray-20',
  } = props

  const style = useMemo(() => {
    const style: any = {}

    if (width) {
      style.width = style.minWidth = `${width}rem`
    }

    if (percentWidth) {
      style.width = style.minWidth = `${percentWidth}%`
    }

    if (height) {
      style.height = style.minHeight = `${height}rem`
    }

    if (percentHeight) {
      style.height = style.minHeight = `${percentHeight}%`
    }

    return style
  }, [ width, height, percentWidth, percentHeight ])

  const skeletonClassName = cx('relative overflow-hidden', s.skeleton, className, {
    'inline-block': inline,
    'rounded-full': circle,
    [`bg-${bgColor}`]: bgColor,
  })

  return (
    <div
      className={skeletonClassName}
      style={style}
    >
      {
        aspect && (
          <div style={{ paddingTop: `${Math.round(100 / aspect)}%` }} />
        )
      }
    </div>
  )
}


export default React.memo(Bone)
