import React from 'react'
import cx from 'classnames'

import type { ColumnsSize } from '../Grid'


export type GridCellProps = {
  className?: string
  tag?: string
  columns?: ColumnsSize
  size?: ColumnsSize
  tabIndex?: number
  'aria-labelledby'?: string
}

const Cell: React.CFC<GridCellProps> = (props) => {
  let {
    children, className, tag = 'div', size = 1,
    tabIndex, 'aria-labelledby': ariaLabelledby,
  } = props

  const rootClassName = cx(className, 'mouse-focus:outline-none', {
    [`col-span-${size}`]: Number(size) > 1,
  })

  const nodeProps = {
    className: rootClassName,
    tabIndex,
    role: 'listitem',
    'aria-labelledby': ariaLabelledby,
  }

  return React.createElement(tag, nodeProps, children)
}


export default Cell
