import React from 'react'
import cx from 'classnames'

import s from './StickyFooterContainer.module.css'


export type StickyFooterContainerProps = {
  className?: string
  bgColor?: 'bg-gold-30' | 'bg-gold-50' | 'bg-gold-70' | 'bg-transparent' | 'bg-red'
  position?: 'top' | 'bottom'
  isVisible?: boolean
  'data-testid'?: string
}

const StickyFooterContainer: React.CFC<StickyFooterContainerProps> = (props) => {
  const { children, className, bgColor = 'bg-gold-30', position = 'bottom', isVisible = true, 'data-testid': dataTestId } = props

  const rootClassName = cx(
    className,
    bgColor,
    s.root,
    isVisible && s.visible,
    'z-floating-header fixed inset-x-0',
    position === 'top' ? 'top-0' : 'bottom-0',
    s[position]
  )

  return (
    <div className={rootClassName} data-testid={dataTestId}>
      {children}
    </div>
  )
}


export default StickyFooterContainer
